@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "CamptonLight";
  src: local("Campton"),
    url("./assets/Fonts/Campton-LightDEMO.otf") format("truetype");
}

@font-face {
  font-family: "CamptonBold";
  src: local("Campton"),
    url("./assets//Fonts/Campton-BoldDEMO.otf") format("truetype");
}

@font-face {
  font-family: "Manrope";
  src: local("Manrope"), url("./assets/Fonts/Manrope.ttf") format("truetype");
}

html,
body,
#app {
  font-family: "Montserrat";
  font-size: 14px;
  @apply bg-default text-default;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
}

input {
  min-width: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.camptonLight {
  font-family: "Montserrat";
}

.camptonBold {
  font-family: "Montserrat";
}

.fade {
  background-image: linear-gradient(
    to left,
    #006580 10%,
    rgba(8, 120, 151, 0.3) 100%
  );
}

.fade2 {
  background-image: linear-gradient(
    to top,
    #006580 10%,
    rgba(8, 120, 151, 0.3) 100%
  );
}

.sec {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(0, 101, 128, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}

.overflowScrollXCustom {
  overflow-y: hidden;
  overflow-x: scroll;
}

/* drawer scrollbar */
.CustomDrawerScroll,
.overflowScrollYCustom {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

/* firefox support */
@supports (scrollbar-width: thin) {
  .CustomDrawerScroll {
    scrollbar-width: none;
    scrollbar-gutter: none;
    scrollbar-arrow-color: #004d62;
  }

  .CustomDrawerScroll:hover {
    scrollbar-width: none;
  }
}

.radio-group .radio:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.radio-group .radio:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.radio-group .radio {
  position: relative;
}

.radio input {
  visibility: hidden;
  position: absolute;
}

.radio label:hover {
  cursor: pointer;
}

.radio input:checked + label {
  background-color: #006580;
  color: white;
}

.texture2 {
  width: 300px;
  height: 300px;
  position: absolute;
  border-radius: 50%;
  right: -30%;
  top: -30%;
  color: #6eaf932e;
  background-color: #6eaf932e;
  box-shadow: -410px 410px;
}

.glassbg {
  backdrop-filter: blur(2px) saturate(159%);
  -webkit-backdrop-filter: blur(2px) saturate(159%);
  background-color: rgba(255, 255, 255, 0.44);
  border-radius: 12px;
  border: 3px solid rgba(209, 213, 219, 0.3);
}

.customShadowDashboard {
  border: 2px solid #0064801a;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.customShadow,
.customShadowNoHover {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border: 1px solid rgb(229 231 235);
}

.customShadowNoHover {
  border: 2px solid rgb(229 231 235);
}

.customShadowDashboard:hover,
.customShadow:hover {
  box-shadow: #00648029 0px 10px 40px -10px;
}

.customBg {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    215deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(122, 175, 189, 1) 0%,
    rgba(0, 101, 128, 1) 100%
  );
}

.textUnderlineCus::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 6px;
  background-color: red;
  transform: skew(50deg) translateX(-50%) rotate(-182deg);
  background: #63a387;
  top: 90%;
  left: 50%;
  /*
      content: "";
        position: absolute;
        left: 60%;
        bottom: 11px;
        width: 170px;
        height: 11px;
        transform: skew(50deg) translateX(-50%) rotate(-182deg);
        background: #63a387;
        z-index: -1;
        font-weight: 700; */
}

.custom-phone-input {
  width: 100% !important;
  height: 50px !important;
  font-size: 1.5rem !important;
}

.highcharts-button-box {
  stroke: #004d62 !important;
}

.highcharts-button-symbol {
  stroke: #004d62 !important;
}

.highcharts-menu {
  background-color: #ecf2ff !important;
  border: 1px solid #004d62 !important;
  border-radius: 0.5rem !important;
  box-shadow: 0 0 0.5rem #004d62 !important;
  color: #004d62 !important;
  padding: 0.2rem !important;
}

.highcharts-menu-item {
  font-family: "Montserrat";
  font-size: 0.9rem !important;
  color: #004d62 !important;
  font-weight: 700 !important;
  transition: all 0.2s ease-in-out;
}

.highcharts-menu-item:hover {
  background-color: #004d62 !important;
  color: #ecf2ff !important;
}

/* hide all text with content Zoom */
.highcharts-range-selector-buttons > .highcharts-label {
  display: none;
}

.custom-z-index {
  z-index: 5;
}

.spinner {
  width: 70.4px;
  height: 70.4px;
  --clr: rgb(0, 101, 128);
  --clr-alpha: rgba(0, 101, 128, 0.21);
  animation: spinner 5s infinite ease;
  transform-style: preserve-3d;
}

.spinner > div {
  background-color: var(--clr-alpha);
  height: 100%;
  position: absolute;
  width: 100%;
  border: 5px solid var(--clr);
}

.spinner div:nth-of-type(1) {
  transform: translateZ(-35.2px) rotateY(180deg);
}

.spinner div:nth-of-type(2) {
  transform: rotateY(-270deg) translateX(50%);
  transform-origin: top right;
}

.spinner div:nth-of-type(3) {
  transform: rotateY(270deg) translateX(-50%);
  transform-origin: center left;
}

.loader-bg {
  z-index: 99999999;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff99;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner div:nth-of-type(4) {
  transform: rotateX(90deg) translateY(-50%);
  transform-origin: top center;
}

.spinner div:nth-of-type(5) {
  transform: rotateX(-90deg) translateY(50%);
  transform-origin: bottom center;
}

.spinner div:nth-of-type(6) {
  transform: translateZ(35.2px);
}

@keyframes spinner {
  0% {
    transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
  }

  50% {
    transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
  }

  100% {
    transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
  }
}
